import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const BackLink = ({ to, children }) => (
    <Link to={to} className="py-1 inline-block link">
        <FontAwesomeIcon icon={["fal", "arrow-left"]} className="mr-2" />
        { children ? children : "Zurück" }
    </Link>
)

export default BackLink
