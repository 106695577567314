import React from "react"
import Layout from "./layout"
import SEO from "../common/seo"
import NavList from "../utils/navlist"
import Img from "gatsby-image"
import BackLink from "../utils/backlink"

const Page = ({ data, childPages, hideTitle }) => {
    const { featured_media, parent_element } = data

    let contentClassName = "py-sm bg-white rounded-t-xl pb-24"

    if (featured_media)
        contentClassName += " -mt-3 relative z-10"
    else
        contentClassName += " min-h-screen mt-sm"

    return (
        <Layout mainClassName="pb-0">
            <SEO title={data ? data.title : "Über Goldfieber"} />

            {data &&
            <article>
                {featured_media && featured_media.localFile &&
                <div className="bg-primary">
                    <div className="container px-0">
                        <Img fluid={featured_media.localFile.childImageSharp.fluid} alt={featured_media.alt_text} title={featured_media.title} />
                    </div>
                </div>
                }

                <div className={contentClassName} style={featured_media && {
                        minHeight: "calc(100vh - 56.25vw)"
                    }}>
                    <div className="container narrow">
                        <div className={hideTitle ? "screen-reader-text" : "mt-8"}>
                            {parent_element &&
                                <BackLink to={`/${parent_element.slug}`} />
                            }
                            <h1 className="mt-2" dangerouslySetInnerHTML={{ __html: data.title }} />
                        </div>

                        <div className="article" dangerouslySetInnerHTML={{ __html: data.content }} />

                        {childPages &&
                        <NavList className="mt-8" nodes={childPages} />
                        }
                    </div>
                </div>
            </article>
            }
        </Layout>
    )
}

export default Page
