import React, { Component } from "react"
import Page from "../components/common/page"
import { graphql } from "gatsby"

class AboutPage extends Component {
    render() {
        const { wordpressPage, allWordpressPage } = this.props.data
        const childPages = allWordpressPage ? allWordpressPage.edges : null

        return (
            <Page data={wordpressPage} childPages={childPages} hideTitle={true} />
        )
    }
}

export default AboutPage

export const query = graphql`
    query AboutPageQuery {
        wordpressPage(slug: {eq: "about"}) {
            title
            content
            featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1024, maxHeight: 576) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        allWordpressPage(filter: {parent_element: {slug: {eq: "about"}}}) {
            edges {
                node {
                    title
                    slug
                    id
                    wordpress_id
                }
            }
        }
    }
`
