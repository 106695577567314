import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NavList = ({ nodes, ...other }) => {
    return (
        <nav {...other}>
            <ul>
                {nodes.map(({ node }) => (
                    <li className="navlist-item" key={`nav-${node.slug}`}>
                        <Link to={`/${node.slug}`} className="link block font-bold py-3 border-b-0 text-base text-gray hover:text-primary">
                            {node.title}
                            <span className="float-right mx-3">
                                <FontAwesomeIcon icon={["fal", "angle-right"]} />
                            </span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default NavList
